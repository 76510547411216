import { useState } from 'react'
import { faBars, faClose, faSearch } from '@fortawesome/free-solid-svg-icons'
import Icon from '../Icon'
import Logo from './Logo'
import Search from './Search'
import theme from '../../theme/cheddar'
import header from '../../styles/components/layout/header'

const Header = ({ navOpen, setNavOpen }) => {
  const [searchOpen, setSearchOpen] = useState(false)

  const onMenuButtonClick = (event) => {
    event.stopPropagation()
    setNavOpen((prevState) => !prevState)
    setSearchOpen(false)
  }

  const onSearchButtonClick = (event) => {
    event.stopPropagation()
    setSearchOpen((prevState) => !prevState)
    setNavOpen(false)
  }

  return (
    <>
      <header className='header-container'>
        <div className='side'>
          <button className='menu-button' onClick={onMenuButtonClick}>
            <Icon icon={navOpen ? faClose : faBars} size='xl' color={theme.colors.white} />
          </button>
          <Logo type={navOpen ? 'secondary' : 'main'} />
        </div>
        <div className='side'>
          <div className='where-to-watch'>
            <a href='/watch'>Where To Watch</a>
          </div>
          <div className='divider'></div>
          <button onClick={onSearchButtonClick}>
            <Icon icon={searchOpen ? faClose : faSearch} size='xl' color={theme.colors.white} />
          </button>
        </div>
      </header>
      <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      <style jsx>{header}</style>
    </>
  )
}

export default Header
