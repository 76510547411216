import Image from 'next/image'
import header from '../../styles/components/layout/header'

const Logo = ({ type }) => {
  return (
    <div className={`logo ${type}`}>
      <a href='/'>
        <Image src={`/cheddar-${type}.svg`} width={232} height={27} alt='Cheddar' />
      </a>
      <style jsx>{header}</style>
    </div>
  )
}

export default Logo
