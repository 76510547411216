import { useEffect, useRef } from 'react'

const useOutsideClick = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)

  }, [ref])
}

const OutsideAlerter = ({ children, onClickOutside }) => {
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, onClickOutside)

  return <div ref={wrapperRef}>{children}</div>
}

export default OutsideAlerter