import { faClose } from '@fortawesome/free-solid-svg-icons'
import Icon from '../Icon'
import banner from '../../styles/components/layout/privacy-banner'

const PrivacyBanner = ({ onDismiss }) => (
  <>
    <div className='banner'>
      <span className='dismiss' data-testid='dismiss' onClick={onDismiss}>
        <Icon icon={faClose} />
      </span>
      <div className='message'>
        <span>
          {`We use cookies and similar technologies on this site to collect identifiers, such as IP address, cookie and device IDs
                as described in our `}
          <a href='/privacy'>
            <b>Privacy Policy.</b>
          </a>
        </span>
      </div>
    </div>
    <style jsx>{banner}</style>
  </>
)

export default PrivacyBanner
