import footer from '../../../styles/components/layout/footer'

const footerLinks = [
  {
    title: 'About Us',
    url: '/media/about-cheddar'
  },
  {
    title: 'Advertise',
    url: '/advertise'
  },
  {
    title: 'Licensing',
    url: 'https://www.executiveinterviews.biz/contact-us/cheddar-website-enquiry/'
  },
  {
    title: 'Contact Us',
    url: 'mailto:contact@cheddar.com'
  },
  {
    title: 'Terms of Service',
    url: '/terms'
  },
  {
    title: 'Privacy Policy',
    url: '/privacy'
  },
  {
    title: 'CA Notice at Collection',
    url: '/privacy-rights-california-residents'
  }
]

const FooterLinks = () => (
  <>
    <div className='footer-links'>
      {footerLinks.map(({ url, title }) => (
        <a key={url} href={url} target={url.startsWith('/') ? '' : '_blank'} rel='noreferrer'>
          {title}
        </a>
      ))}
    </div>
    <style jsx>{footer}</style>
  </>
)

export default FooterLinks
