import { createContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import cheddar from '../theme/cheddar'

export const MediaQueryContext = createContext({ isMobile: false, isTablet: false })

const MediaQueryProvider = ({ children }) => {
  /* useMediaQuery from 'react-responsive' happens on Client side only as it uses Window object.
     To prevent rehydrating errors and to have html received from SSR to be equal to html on CSR
     on first render, we need to make sure that 'isMobile' and 'isTablet' values are true only on Client
  */
  const [isClientSide, setIsClientSide] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: ${cheddar.breakpoints.sm})` })
  const isTablet = useMediaQuery({ query: `(max-width: ${cheddar.breakpoints.md})` })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClientSide(true)
    }
  }, [])

  return (
    <MediaQueryContext.Provider value={{ isMobile: isClientSide && isMobile, isTablet: isClientSide && isTablet }}>
      {children}
    </MediaQueryContext.Provider>
  )
}

export default MediaQueryProvider
