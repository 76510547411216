export default {
  breakpoints: {
    sm: '540px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
    xxl: '1680px'
  },
  colors: {
    black: '#000',
    blackGrey: '#151515',
    darkGrey: '#434343',
    white: '#ffffff',
    cheddarPink: '#fa2685',
    mutedCheddarPink: '#ff75b2',
    nero: '#151515',
    cheddarGrey: '#dbe3fa',
    yellow: '#f7eb55',
    grey: '#666666',
    lightGrey: '#bbbbbb',
    lightGrey1: '#8a8a8a',
    lightestGrey: '#d8d8d8',
    ghostWhite: '#f4f4f5',
    fiord: '#4b5c6b'
  }
}
