import { useState } from 'react'
import { setCookie, getCookie } from 'cookies-next'
import Logo from '../Logo'
import PrivacyBanner from '../PrivacyBanner'
import FooterLinks from './FooterLinks'
import SocialMediaLinks from './SocialMediaLinks'
import footer from '../../../styles/components/layout/footer'

const CCPA_BANNER = 'hasDismissedCcpaBanner'

const Footer = () => {
  const hasDismissedCcpaBanner = getCookie(CCPA_BANNER)
  const [ccpaBannerDismissed, setCcpaBannerDismissed] = useState(hasDismissedCcpaBanner)

  const onBannerDismiss = () => {
    setCookie(CCPA_BANNER, 'true')
    setCcpaBannerDismissed(true)
  }

  return (
    <>
      <div className='footer-container'>
        <div className='cheddar-logo'>
          <Logo type='secondary' />
        </div>
        <FooterLinks />
        <div className='footer-subtext'>Follow us on</div>
        <SocialMediaLinks />
        <div className='footer-subtext'>© {new Date().getFullYear()} Cheddar, Inc.</div>
      </div>
      {!ccpaBannerDismissed && <PrivacyBanner onDismiss={onBannerDismiss} />}
      <style jsx>{footer}</style>
    </>
  )
}

export default Footer
