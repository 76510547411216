import { useEffect, useRef, useState } from 'react'
import OutsideAlerter from '../../hooks/useOutsideClick'
import style from '../../styles/components/layout/search'

const Search = ({ searchOpen, setSearchOpen }) => {
  const inputElement = useRef(null)
  const [searchTerm, setSearchTerm] = useState('')

  const onChange = (e) => {
    const keywordsCount = searchTerm.trim().split(' ').length
    const keywordsLimit = 25

    if (keywordsCount < keywordsLimit) {
      setSearchTerm(e.target.value)
    }
  }

  const onSubmit = (e) => {
    if (e.key === 'Enter' && searchTerm?.trim().length > 0) {
      e.preventDefault()
      window.location.replace('/search?q=' + searchTerm)
      setSearchOpen(false)
      setSearchTerm('')
    }
  }

  useEffect(() => {
    inputElement.current.focus()
  }, [searchOpen])

  return (
    <>
      <OutsideAlerter onClickOutside={() => setSearchOpen(false)}>
        <div className={`search-container ${searchOpen ? 'visible' : ''}`}>
          <input
            className='input'
            ref={inputElement}
            value={searchTerm}
            placeholder='Type your search here'
            onChange={onChange}
            onKeyDown={onSubmit}
          />
        </div>
      </OutsideAlerter>
      <div className={`overlay ${searchOpen ? 'visible' : ''}`} />
      <style jsx>{style}</style>
      <style jsx global>{`
        body {
          overflow: ${searchOpen ? 'hidden' : 'unset'}
        }`}</style>
    </>
  )
}

export default Search
