import { capitalize } from '../../utils/capitalizeFirstLetter'
import socials from '../socials'

const extractNavigation = (data) => {
  const navigation = data?.map((tab) => ({
    headline: tab?.fields?.name,
    expandable: true,
    list: tab?.fields?.items?.map((tabItem) => ({
      title: tabItem?.fields?.title || capitalize(tabItem?.fields?.name),
      path: `${tab?.fields?.name?.toLowerCase()}/${tabItem?.fields?.slug || tabItem?.fields?.name}`
    }))
  }))

  return [
    { headline: 'Where to Watch', expandable: false, slug: '/watch' },
    ...navigation,
    { headline: 'Follow Us', expandable: true, list: socials },
    { headline: 'Need2Know', expandable: false, slug: '/need2know' }
  ]
}

export default extractNavigation
