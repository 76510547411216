const fetchQueryWrapper = async (params = {}, transform, isPreview = false, env = null) => {
  const envPath = env ? `environments/${env}/` : ''
  const url = new URL(`https://${isPreview ? 'preview' : 'cdn'}.contentful.com/spaces/vcjvsnaeixme/${envPath}entries`)
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))

  const res = await fetch(url, {
    headers: {
      Authorization: isPreview ? `Bearer ${process.env.CONTENTFUL_PREVIEW_API}` : `Bearer ${process.env.CONTENTFUL_CONTENT_DELIVERY_API}`
    }
  })
  const data = await res.json()

  return transform(data)
}

export default fetchQueryWrapper
