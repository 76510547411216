import OutsideAlerter from '../../../hooks/useOutsideClick'
import NavigationTab from './NavigationTab'
import style from '../../../styles/components/layout/navigation'

const Navigation = ({ navigation, navOpen, setNavOpen }) => (
  <>
    <OutsideAlerter onClickOutside={() => setNavOpen(false)}>
      <div className={`navigation-container ${navOpen ? 'open' : ''}`}>
        {navigation.map((tab, idx) => (
          <NavigationTab key={idx} tab={tab} />
        ))}
      </div>
    </OutsideAlerter>
    <style jsx>{style}</style>
  </>
)

export default Navigation
