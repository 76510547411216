export const CONTENT_TYPE = {
  BANNER: 'banner',
  HOMEPAGE: 'homepage',
  NAVIGATION: 'navigation',
  STORY: 'story',
  CATEGORY: 'category',
  SHOW: 'show',
  AUTHOR: 'author',
  NEED2KNOW: 'need2know',
  WHERETOWATCH: 'whereToWatch',
  STATIC: 'page',
  VIDEO: 'videoFile',
  PROJECTS: 'projects'
}
