import { faFacebook, faInstagram, faLinkedin, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

export default [
  { title: 'Twitter', icon: faXTwitter, url: 'https://twitter.com/cheddar', external: true },
  { title: 'Facebook', icon: faFacebook, url: 'https://www.facebook.com/cheddar/', external: true },
  { title: 'Instagram', icon: faInstagram, url: 'https://www.instagram.com/cheddar/', external: true },
  { title: 'TikTok', icon: faTiktok, url: 'https://www.tiktok.com/@cheddar', external: true },
  { title: 'LinkedIn', icon: faLinkedin, url: 'https://www.linkedin.com/company/cheddar-inc-/', external: true },
  { title: 'Cheddar YouTube', icon: faYoutube, url: 'https://www.youtube.com/cheddar', external: true }
]
