import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Script from 'next/script'
import MediaQueryProvider from '../context/MediaQueryProvider'
import { registerServiceWorker, registerAirshipSdk } from '../utils/airship'
import { CONTENT_TYPE } from '../utils/contentTypes'
import TABOOLA_SCRIPT from '../utils/TABOOLA_SCRIPT'
import logger from '../utils/winston-logger'
import NAVIGATION_FALLBACK from '../data/navigation'
import extractNavigation from '../data/transformers/extractNavigation'
import Layout from '../components/Layout/Layout'
import '../public/fonts/style.css'
import fetchQueryWrapper from '../network/fetchQueryWrapper'
import resolveResponse from 'contentful-resolve-response'

config.autoAddCss = false

const CheddarApp = ({ Component, pageProps, navigation }) => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-5RQV298'
    })

    registerServiceWorker()
    registerAirshipSdk()
  }, [])

  return (
    <>
      <Script
        id='taboola'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: TABOOLA_SCRIPT
        }}
      />
      <MediaQueryProvider>
        <Layout hideLayout={Component.hideLayout} navigation={navigation || NAVIGATION_FALLBACK}>
          <Component {...pageProps} />
        </Layout>
      </MediaQueryProvider>
    </>
  )
}

CheddarApp.getInitialProps = async () => {
  try {
    const response = await fetchQueryWrapper({ content_type: CONTENT_TYPE.NAVIGATION }, resolveResponse)
    const navigation = extractNavigation(response)

    return { navigation }
  } catch (error) {
    logger?.error('Error in _app.page.js ', error)
    return { notFound: true }
  }
}

export default CheddarApp
