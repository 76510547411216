import { useState } from 'react'
import Footer from './Footer/Footer'
import Header from './Header'
import Navigation from './Navigation/Navigation'
import base from '../../styles/base'
import shared from '../../styles/shared'

const Layout = ({ children, navigation, hideLayout = false }) => {
  const [navOpen, setNavOpen] = useState(false)

  return (
    <>
      {!hideLayout && <Header navOpen={navOpen} setNavOpen={setNavOpen} />}
      {!hideLayout && <Navigation navigation={navigation} navOpen={navOpen} setNavOpen={setNavOpen} />}
      {children}
      {!hideLayout && <Footer />}
      <style jsx global>
        {base}
      </style>
      <style jsx global>
        {shared}
      </style>
    </>
  )
}

export default Layout
