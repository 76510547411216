import { useEffect, useState } from 'react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import Icon from '../../Icon'
import theme from '../../../theme/cheddar'
import style from '../../../styles/components/layout/navigation'

const ExpandedTab = ({ tab }) => (
  <>
    <div className='navigation-tab-expanded'>
      {tab?.list?.map((tabItem) => (
        <div className='tab-item' key={tabItem.title}>
          {tabItem.icon && <Icon icon={tabItem.icon} size='sm' />}
          <a href={tabItem.url || `/${tabItem.path}`} target={tabItem.url ? '_blank' : ''} rel='noreferrer'>
            {tabItem.title}
          </a>
        </div>
      ))}
    </div>
    <style jsx>{style}</style>
  </>
)

const NavigationTab = ({ tab }) => {
  const { isTablet } = useMediaQuery()
  const [isTabExpanded, setIsTabExpanded] = useState(isTablet)

  useEffect(() => {
    setIsTabExpanded(isTablet)
  }, [isTablet])

  const handleMouseEvent = isTablet
    ? { onClick: () => setIsTabExpanded((prevState) => !prevState) }
    : {
        onMouseEnter: () => setIsTabExpanded(true),
        onMouseLeave: () => setIsTabExpanded(false)
      }

  const tabColor = theme.colors[isTabExpanded && !isTablet ? 'white' : 'cheddarPink']

  return (
    <>
      <div className='navigation-details' {...handleMouseEvent}>
        <div className='navigation-headline'>
          <a {...(tab?.slug ? { href: tab.slug } : { role: 'button' })} style={{ color: tabColor }}>
            {tab.headline}
          </a>
          {tab.expandable && (
            <Icon
              icon={isTabExpanded && isTablet ? faChevronDown : faChevronUp}
              rotate={isTablet ? 0 : 90}
              size='2xs'
              width={15}
              color={tabColor}
            />
          )}
        </div>
        {tab.expandable && isTabExpanded && <ExpandedTab key={tab.headline} tab={tab} />}
      </div>
      <style jsx>{style}</style>
    </>
  )
}

export default NavigationTab
