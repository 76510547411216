export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('push-worker.js').then(
        (registration) => {
          console.log('ServiceWorker registration successful with scope: ', registration.scope)
        },
        (err) => {
          console.log('ServiceWorker registration failed: ', err)
        }
      )
    })
  }
}

export const registerAirshipSdk = () => {
  const SAFARI_BROWSER = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') < 0

  if (window.UA) {
    window.UA.then((sdk) => {
      if (sdk.permission === 'default') {
        if (SAFARI_BROWSER) {
          document.addEventListener('click', () => sdk.register())
          return
        }
        sdk.register()
      }
    })
  } else {
    setTimeout(registerAirshipSdk, 300)
  }
}
