import { isAndroid, isIOS } from 'react-device-detect'
import { getAndroidDeepLink, getIOSDeepLink } from 'url-to-deep-link'
import socials from '../../../data/socials'
import Icon from '../../Icon'
import footer from '../../../styles/components/layout/footer'

const SocialMediaLinks = () => (
  <>
    <div className='socialMediaLinks'>
      {socials.map(({ url, icon }) => {
        const deepLink = (isIOS && getIOSDeepLink(url)) || (isAndroid && getAndroidDeepLink(url))

        return (
          <a key={url} className='socialMediaLogo' href={deepLink || url} target='_blank' rel='noreferrer'>
            <Icon key={url} icon={icon} />
          </a>
        )
      })}
    </div>
    <style jsx>{footer}</style>
  </>
)

export default SocialMediaLinks
